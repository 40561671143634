import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userAtom } from '../../state/index';

const withAuthentication = (WrappedComponent) => {
  const AuthenticatedComponent = (props) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const token = localStorage.getItem('token'); // Fetch the token from localStorage
    const [user, setUser] = useAtom(userAtom);

    useEffect(() => {
      // Make a request to the checkAuth route
      fetch('https://solidity-scanner.onrender.com/api/checkAuth', {
        // credentials: 'include', // Include cookies in the request if using sessions
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {

          return response.json();
        })
        .then((data) => {
          setLoading(false);
          if (data && !data.authenticated) {
            // User is authenticated
            navigate('/login', { replace: true });
            return
          }

          console.log('user is', data);

          setUser({
            id: data.user.id,
            username: data.user.username,
            email: data.user.email,
            availableScans: data.user.availableScans,
          });
        })
        .catch((error) => {
          console.error('Error checking authentication:', error);
          setLoading(false);
          navigate('/login', { replace: true });
          // Handle the error, redirect or display an error message
        });
    }, [navigate]);

    if (loading) {
      // If the request is still in process, show a loading spinner
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
      );
    }

    // User is authenticated, render the wrapped component
    return <WrappedComponent {...props} />;
    // return <Login />
  };

  return AuthenticatedComponent;
};

export default withAuthentication;
